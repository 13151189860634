import React, { useEffect, useState } from "react";
import { renderMetaTags } from "react-datocms";
import Head from "next/head";
import { useRouter } from "next/router";
import { NextSeo } from "next-seo";
import { SUPPORTED_LOCALES } from "@/constants/constants";
import { removeTrailingSlash } from "@/utils/functions";

function MetaTags(props) {
  const { seoData, noFollow, noIndex, locales = [] } = props;
  const { asPath, route, locale, defaultLocale } = useRouter();

  const [showIndex, setShowIndex] = useState(false);
  const [showFollow, setShowFollow] = useState(false);

  // let hostname = typeof window !== "undefined" ? window.location.origin : "http://localhost:3000";
  const hostname = `https://${process.env.NEXT_PUBLIC_HOSTNAME}` || "http://localhost:3000";

  const langList = locales?.length ? locales : SUPPORTED_LOCALES;

  useEffect(() => {
    if (route?.includes("/404") || route?.includes("/500")) {
      setShowIndex(true);
      setShowFollow(true);
    } else {
      setShowIndex(noIndex);
      setShowFollow(noFollow);
    }
  }, [noIndex, noFollow, route]);

  const canonicalUrl = removeTrailingSlash(
    `${hostname}${locale !== defaultLocale ? `/${locale}` : ""}${asPath}`,
  );

  return (
    <>
      <NextSeo
        languageAlternates={langList.map((localeItem) => ({
          hrefLang: localeItem,
          href: removeTrailingSlash(
            `${hostname}${localeItem !== defaultLocale ? `/${localeItem}` : ""}${asPath}`,
          ),
        }))}
        canonical={canonicalUrl}
        openGraph={{
          url: canonicalUrl,
        }}
        noindex={showIndex}
        nofollow={showFollow}
      />
      <Head>
        <link
          rel="alternate"
          hrefLang="x-default"
          href={removeTrailingSlash(`${hostname}${asPath}`)}
        />
        <meta name="viewport" content="initial-scale=1.0, width=device-width" />
        {seoData?.length > 0 && renderMetaTags(seoData)}
      </Head>
    </>
  );
}

export default React.memo(MetaTags);
